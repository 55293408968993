import { Grid, Typography, Snackbar, Alert, Box, IconButton, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { useStore } from '../hooksStore/store';
import ClassBox from './ClassBox';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { getDatePart, getDateForRequest } from '../utils/dateUtils';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function Calendar({ refreshData }) {
    const [state, dispatch] = useStore();
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/calendar`, method: "GET" });
    const { runFetch: checkInRunFetch, data: checkInData, error: checkInError, loading: checkInLoading } = useFetch({ url: `${BASE_URL}/reservation/check-in`, method: "POST" });
    const { runFetch: checkOutRunFetch, data: checkOutData, error: checkOutError, loading: checkOutLoading } = useFetch({ url: `${BASE_URL}/reservation/check-out`, method: "POST" });
    const [calendarByDay, setCalendarByDay] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [calendarButtonDirection, setCalendarButtonDirection] = useState(null);
    const [disabledBackButton, setDisabledBackButton] = useState(false);
    const [disabledForwardButton, setDisabledForwardButton] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [successCheckInAlertOpen, setSuccessCheckInAlertOpen] = useState(false);
    const [successCheckOutAlertOpen, setSuccessCheckOutAlertOpen] = useState(false);
    const [loadingId, setLoadingId] = useState(null);

    const setStartAndEndDate = (date) => {
        const dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1;
        const start = new Date(date).setDate(date.getDate() - dayIndex);
        const end = new Date(date).setDate(date.getDate() + 5 - dayIndex);
        dispatch("SET_CALENDAR_DATES", { start: start, end: end, disabledBackButton: false, disabledForwardButton: false });
    };

    const getPreviousStartAndEndDate = () => {
        const previousStartDate = new Date(startDate);
        previousStartDate.setDate(previousStartDate.getDate() - 7);
        const previousEndDate = new Date(endDate);
        previousEndDate.setDate(previousEndDate.getDate() - 7);
        return { start: previousStartDate, end: previousEndDate };
    };

    const getNextStartAndEndDate = () => {
        const previousStartDate = new Date(startDate);
        previousStartDate.setDate(previousStartDate.getDate() + 7);
        const previousEndDate = new Date(endDate);
        previousEndDate.setDate(previousEndDate.getDate() + 7);
        return { start: previousStartDate, end: previousEndDate };
    }

    useEffect(() => {
        if (state.user && !state.calendarLoaded) {
            setStartAndEndDate(new Date());
            runFetch({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.calendarLoaded]);

    useEffect(() => {
        if (state.calendarStartDate && state.calendarEndDate) {
            setStartDate(state.calendarStartDate);
            setEndDate(state.calendarEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.calendarStartDate, state.calendarEndDate]);

    useEffect(() => {
        setDisabledBackButton(state.disabledBackButton);
        setDisabledForwardButton(state.disabledForwardButton);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.disabledBackButton, state.disabledForwardButton]);

    useEffect(() => {
        if (state.calendarLoaded && state.reservationLoaded) {
            const cal = [[], [], [], [], [], [], []];
            state.calendar.forEach(calendar => {
                if (calendar.status.statusName !== "deleted") {
                    const date = new Date(calendar.startTime);
                    const dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1;
                    const reservationForThisClass = state.reservation.filter(reservation => reservation.calendar.id === calendar.id);
                    calendar.reservations = reservationForThisClass;
                    const reservationOfActiveUser = reservationForThisClass.find(reservation => reservation.user.id === state.user.id);
                    calendar.activeUserReservationId = reservationOfActiveUser ? reservationOfActiveUser.id : null;
                    if (state.user.admin) {
                        calendar.users = reservationForThisClass.map(reservation => reservation.user.userName);
                    }
                    cal[dayIndex].push(calendar);
                }
            });
            setCalendarByDay(state.calendar.length > 0 ? cal : []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.calendarLoaded, state.reservationLoaded]);

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch("SET_CALENDAR", data);
            if (data.length > 0) {
                setStartAndEndDate(new Date(data[0].startTime));
                setCalendarButtonDirection(null);
                setDisabledBackButton(false);
                setDisabledForwardButton(false);
            } else if (calendarButtonDirection === "back") {
                const { start, end } = getPreviousStartAndEndDate();
                dispatch("SET_CALENDAR_DATES", { start: start, end: end, disabledBackButton: true, disabledForwardButton: false });
                setCalendarButtonDirection(null);
            } else if (calendarButtonDirection === "forward") {
                const { start, end } = getNextStartAndEndDate();
                dispatch("SET_CALENDAR_DATES", { start: start, end: end, disabledBackButton: false, disabledForwardButton: true });
                setCalendarButtonDirection(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    useEffect(() => {
        if (!checkInLoading) {
            setLoadingId(null);
        }
        if (!checkInLoading && checkInError) {
            setErrorAlertOpen(true);
        }
        if (!checkInLoading && !checkInError && checkInData) {
            refreshData();
            setSuccessCheckInAlertOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkInLoading, checkInError, checkInData]);

    useEffect(() => {
        if (!checkOutLoading) {
            setLoadingId(null);
        }
        if (!checkOutLoading && checkOutError) {
            setErrorAlertOpen(true);
        }
        if (!checkOutLoading && !checkOutError && checkOutData) {
            refreshData();
            setSuccessCheckOutAlertOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutLoading, checkOutError, checkOutData]);

    const checkInUser = (calendarId) => {
        setLoadingId(calendarId);
        checkInRunFetch({
            body: {
                userId: state.user.id,
                calendarId: calendarId
            }
        });
    };

    const checkOutUser = (reservationId) => {
        setLoadingId(reservationId);
        checkOutRunFetch({
            body: {
                reservationId: reservationId
            }
        });
    };

    const handleErrorAlertClose = () => {
        setErrorAlertOpen(false);
    }

    const handleSuccessCheckInAlertClose = () => {
        setSuccessCheckInAlertOpen(false);
    }

    const handleSuccessCheckOutAlertClose = () => {
        setSuccessCheckOutAlertOpen(false);
    }

    const getPreviousWeekCalendar = () => {
        setCalendarButtonDirection("back");
        const { start, end } = getPreviousStartAndEndDate();
        const startDate = getDateForRequest(start);
        const endDate = getDateForRequest(end);
        setDisabledBackButton(true);
        setDisabledForwardButton(true);
        runFetch({
            params: {
                startDate: startDate,
                endDate: endDate
            }
        });
    }

    const getNextWeekCalendar = () => {
        setCalendarButtonDirection("forward");
        const { start, end } = getNextStartAndEndDate();
        const startDate = getDateForRequest(start);
        const endDate = getDateForRequest(end);
        setDisabledBackButton(true);
        setDisabledForwardButton(true);
        runFetch({
            params: {
                startDate: startDate,
                endDate: endDate
            }
        });
    }

    if (loading) {
        return <Grid container alignItems="center" justifyContent="center"><CircularProgress /></Grid>;
    }

    if (!state.user || !state.calendarLoaded || !calendarByDay || !state.reservationLoaded) {
        return null;
    }

    return (
        <Grid container direction="column" sx={{ padding: "16px" }}>
            <Typography variant='h4'>{state.user.admin ? 'Bejelentkezések' : 'Bejelentkezés'}</Typography>


            <Grid item container sx={{ padding: "32px 0" }}>
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    <IconButton sx={{ backgroundColor: '#efefef', marginRight: '8px' }} disabled={disabledBackButton} onClick={() => getPreviousWeekCalendar()}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Typography variant='h6'><b>{getDatePart(startDate)} - {getDatePart(new Date(endDate).setDate(new Date(endDate).getDate() - 1))}</b></Typography>
                    <IconButton sx={{ backgroundColor: '#efefef', marginLeft: '8px' }} disabled={disabledForwardButton} onClick={() => getNextWeekCalendar()}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Box sx={{ flexGrow: 1 }}>
                {calendarByDay.length > 0 ? (
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
                        <Grid item xs={12} sm={4} md={4} lg={2.4} >
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant='h5' sx={{ marginBottom: "16px" }}>Hétfő</Typography>
                                </Grid>
                                <Grid item>
                                    {calendarByDay[0].map(monday => (
                                        <ClassBox calendarElement={monday} checkInUser={checkInUser} checkOutUser={checkOutUser} refreshData={refreshData} loadingId={loadingId} key={monday.startTime} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2.4} >
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant='h5' sx={{ marginBottom: "16px" }}>Kedd</Typography>
                                </Grid>
                                <Grid item>
                                    {calendarByDay[1].map(tuesday => (
                                        <ClassBox calendarElement={tuesday} checkInUser={checkInUser} checkOutUser={checkOutUser} refreshData={refreshData} loadingId={loadingId} key={tuesday.startTime} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2.4} >
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant='h5' sx={{ marginBottom: "16px" }}>Szerda</Typography>
                                </Grid>
                                <Grid item>
                                    {calendarByDay[2].map(wednesday => (
                                        <ClassBox calendarElement={wednesday} checkInUser={checkInUser} checkOutUser={checkOutUser} refreshData={refreshData} loadingId={loadingId} key={wednesday.startTime} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2.4} >
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant='h5' sx={{ marginBottom: "16px" }}>Csütörtök</Typography>
                                </Grid>
                                <Grid item>
                                    {calendarByDay[3].map(thursday => (
                                        <ClassBox calendarElement={thursday} checkInUser={checkInUser} checkOutUser={checkOutUser} refreshData={refreshData} loadingId={loadingId} key={thursday.startTime} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2.4} >
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                <Grid item>
                                    <Typography variant='h5' sx={{ marginBottom: "16px" }}>Péntek</Typography>
                                </Grid>
                                <Grid item>
                                    {calendarByDay[4].map(friday => (
                                        <ClassBox calendarElement={friday} checkInUser={checkInUser} checkOutUser={checkOutUser} refreshData={refreshData} loadingId={loadingId} key={friday.startTime} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent="center">
                        <Typography variant='h5'>Nincs megjeleníthető naptár!</Typography>
                    </Grid>
                )}
            </Box>
            <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={handleErrorAlertClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width: '100%' }}>
                    {checkInError ? getReadableErrorMessage(checkInError) : getReadableErrorMessage(checkOutError)}
                </Alert>
            </Snackbar>
            <Snackbar open={successCheckInAlertOpen} autoHideDuration={6000} onClose={handleSuccessCheckInAlertClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleSuccessCheckInAlertClose} severity="success" sx={{ width: '100%' }}>
                    Sikeres bejelentkezés!
                </Alert>
            </Snackbar>
            <Snackbar open={successCheckOutAlertOpen} autoHideDuration={6000} onClose={handleSuccessCheckOutAlertClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleSuccessCheckOutAlertClose} severity="success" sx={{ width: '100%' }}>
                    Sikeres lejelentkezés!
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default Calendar;