import { Grid, Typography, Box, Snackbar, Alert, Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { useStore } from '../hooksStore/store';
import ClassBoxForModification from './ClassBoxForModification';
import { getDatePart } from '../utils/dateUtils';
import ClassModificationDialog from './ClassModificationDialog';

function CalendarModification() {
    const [state, dispatch] = useStore();
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/calendar`, method: "GET" });
    const [calendarByDay, setCalendarByDay] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedClass, setSelectedClass] = useState(null);
    const [classModificationDialogOpen, setClassModificationDialogOpen] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");

    const setStartAndEndDate = (date) => {
        const dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1;
        setStartDate(new Date(date).setDate(date.getDate() - dayIndex));
        setEndDate(new Date(date).setDate(date.getDate() + 5 - dayIndex));
    };

    useEffect(() => {
        if (state.user && !state.calendarModificationLoaded) {
            runFetch({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (state.calendarModificationLoaded) {
            if (state.calendarModification.length > 0) {
                setStartAndEndDate(new Date(state.calendarModification[0].startTime));
            } else {
                const currentDate = new Date();
                if (currentDate.getDay() === 6) {
                    currentDate.setDate(currentDate.getDate() + 2);
                } else if (currentDate.getDay() === 0) {
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                setStartAndEndDate(currentDate);
            }
            const cal = [[], [], [], [], [], [], []];
            state.calendarModification.forEach(calendar => {
                const date = new Date(calendar.startTime);
                const dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1;
                cal[dayIndex].push(calendar);
            });
            setCalendarByDay(state.calendarModification.length > 0 ? cal : []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.calendarModificationLoaded]);

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch("SET_CALENDAR_MODIFICATION", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    const onClassClick = (calendarElement) => {
        setSelectedClass(calendarElement);
        setClassModificationDialogOpen(true);
    };

    const onDialogClose = () => {
        setSelectedClass(null);
        setClassModificationDialogOpen(false)
    };

    const afterClassHasBeenModified = (isNew) => {
        onDialogClose();
        setSuccessAlertMessage(isNew ? "Az óra sikeresen létrehozva!" : "Az óra sikeresen módosítva!");
        setOpenSuccessAlert(true);
        runFetch({});
    };

    const afterClassHasBeenRemoved = () => {
        onDialogClose();
        setSuccessAlertMessage("Az óra sikeresen törölve!");
        setOpenSuccessAlert(true);
        runFetch({});
    };

    if (loading) {
        return <Grid container alignItems="center" justifyContent="center"><CircularProgress /></Grid>;
    }

    if (!state.user || !state.calendarModificationLoaded || calendarByDay === undefined || !startDate || !endDate) {
        return null;
    }

    return (
        <>
            <Grid container direction="column" sx={{ padding: "16px" }}>
                <Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: '16px' }}>
                    <Grid item xs={6}>
                        <Typography variant='h4'>Naptár módosítás</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="end">
                            <Button variant="outlined" sx={{ color: '#555', borderColor: '#555' }} onClick={() => onClassClick("")}>Új óra létrehozása</Button>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item container sx={{ padding: "32px 0" }}>
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Typography variant='h6'><b>{getDatePart(startDate)} - {getDatePart(new Date(endDate).setDate(new Date(endDate).getDate() - 1))}</b></Typography>
                    </Grid>
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                    {calendarByDay.length > 0 ? (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
                            <Grid item xs={12} sm={4} md={4} lg={2.4} >
                                <Grid container direction="column" alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant='h5' sx={{ marginBottom: "16px" }}>Hétfő</Typography>
                                    </Grid>
                                    <Grid item>
                                        {calendarByDay[0].map(monday => (
                                            <ClassBoxForModification calendarElement={monday} onClassClick={onClassClick} key={monday.startTime} />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2.4} >
                                <Grid container direction="column" alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant='h5' sx={{ marginBottom: "16px" }}>Kedd</Typography>
                                    </Grid>
                                    <Grid item>
                                        {calendarByDay[1].map(tuesday => (
                                            <ClassBoxForModification calendarElement={tuesday} onClassClick={onClassClick} key={tuesday.startTime} />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2.4} >
                                <Grid container direction="column" alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant='h5' sx={{ marginBottom: "16px" }}>Szerda</Typography>
                                    </Grid>
                                    <Grid item>
                                        {calendarByDay[2].map(wednesday => (
                                            <ClassBoxForModification calendarElement={wednesday} onClassClick={onClassClick} key={wednesday.startTime} />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2.4} >
                                <Grid container direction="column" alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant='h5' sx={{ marginBottom: "16px" }}>Csütörtök</Typography>
                                    </Grid>
                                    <Grid item>
                                        {calendarByDay[3].map(thursday => (
                                            <ClassBoxForModification calendarElement={thursday} onClassClick={onClassClick} key={thursday.startTime} />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={2.4} >
                                <Grid container direction="column" alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant='h5' sx={{ marginBottom: "16px" }}>Péntek</Typography>
                                    </Grid>
                                    <Grid item>
                                        {calendarByDay[4].map(friday => (
                                            <ClassBoxForModification calendarElement={friday} onClassClick={onClassClick} key={friday.startTime} />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container justifyContent="center">
                            <Typography variant='h5'>Nincs megjeleníthető naptár!</Typography>
                        </Grid>
                    )}
                </Box>
            </Grid>
            <ClassModificationDialog open={classModificationDialogOpen} selectedClass={selectedClass} startDate={getDatePart(startDate)} afterClassHasBeenModified={(isNew) => afterClassHasBeenModified(isNew)} afterClassHasBeenRemoved={() => { afterClassHasBeenRemoved() }} onDialogClose={() => onDialogClose()} />
            <Snackbar open={openSuccessAlert} onClose={() => setOpenSuccessAlert(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert severity="success" onClose={() => setOpenSuccessAlert(false)} sx={{ width: '100%' }}>
                    {successAlertMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default CalendarModification;